import React, { useState,useEffect,useRef, Fragment} from 'react';
import {Empty, Modal,Form,Input,Button,Switch, Upload, Dropdown, Menu, Select, notification, Transfer, message } from 'antd';
import MultiImageInput from 'react-multiple-image-input';


import { connect } from 'dva';

const formItemLayout = { labelCol: {xs: { span: 24, },  sm: { span: 24, },}};
const { TextArea } = Input;
let typeList = ['All', 'Student', 'Employee']

 
const AddEdit =props => {
	const [form] = Form.useForm();
	const { dispatch} = props;
	const [catlist, setCatlist] = useState([])
	const [count, setCount] = useState(0)
	const [dcount, setDCount] = useState(0)
	const [btnDis, setBtnDis] = useState(false)
	const [galleryImageUriList,setGalleryImageUriList] = useState({});
	const [gallaryImagesList, setGallaryImagesList] = useState([]);

	
	useEffect(() => {
		let unmounted = false;
		return () => {unmounted = true;}
    },[dispatch])
	
	
	useEffect(() => {
		let unmounted = false;

		let data = props.detail;		
		if(props.detail){
			form.setFieldsValue({
			  ['title']: data.title, 
			  ['message']: data.message, 
			  ['type']: data.type, 

			});

			let images = [{file : data.banner_image }]
				console.log('images' , images)
				setGalleryImageUriList(images.map((galImg) => {
					return galImg.file;
				}));
				setGallaryImagesList(images.map((galImg) => {
					return galImg.file;
				}));
		
		
		}
		else{ 
			setGalleryImageUriList({})
			setGalleryImageUriList([])
			
			form.resetFields();
		 }
		
		return () => {unmounted = true;}
    },[props.visible])
	
	useEffect(() => {
		let unmounted = false;
		let {announcements} = props;
		setCatlist(announcements.list ? announcements.list.data:[]);
		return () => {unmounted = true;}
    },[props.announcements.list])

	const convertToFormData = (values) => {
		const formData = new FormData();
		formData.append("banner_image",values.banner_image)
		formData.append("title",values.title)
		formData.append("type",values.type)
		formData.append("message",values.message)
		return formData;
	}

	
	const onFinish= val=>{



		if(gallaryImagesList.length === 0) {
			return	message.error('please upload banner image');
		  } 
	

		  
		val.banner_image = gallaryImagesList[0];
		const formData = convertToFormData(val);



		
		setBtnDis(true);
		if(props.detail){

		formData.append("_id", props.detail._id)

		

			// val._id = props.detail._id
			dispatch({type: 'announcements/announcementEdit',  payload: formData,});
		}
		else{
			
			dispatch({type: 'announcements/announcementAdd',  payload: formData,});
		}
	}
	
	useEffect(() => {
		let unmounted = false;
		let add = props.announcements.add
		if(!unmounted && add.count > count && add.status){
			setBtnDis(false);
		  setCount(add.count);		  
		  props.returnData('success');
		}else if(!unmounted && add.count > count && !add.status){
		  setBtnDis(false);
		  setCount(add.count);
		}
		
		// Edit
		let edit = props.announcements.edit
		if(!unmounted && edit.count > dcount && edit.status){
		  setBtnDis(false);
		  setDCount(edit.count);
		  
		  props.returnData('success');
		}else if(!unmounted && edit.count > dcount && !edit.status){
		  setBtnDis(false);
		  setDCount(edit.count);
		}
		return () => {
			unmounted = true;
		}
	},[props.announcements])
	
	const cancelFun = ()=>{
		if(!props.detail)
			form.resetFields();
		props.closeModel()
	}



	const onUploadGallery = (data) => {
		const images = Object.values(data).map((image,i) => {
			if(image.startsWith(process.env.REACT_APP_ApiUrl)) {
				return image;
			}else{
				return dataURLtoFile(image,'image_'+i);
			}
		})
		setGalleryImageUriList(data);
		setGallaryImagesList([...images]);
		
	  }

	  const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);   
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }


	
return (
	<Modal visible={props.visible} title={props.detail?'Edit Announcement':'Add Announcement'} onCancel={cancelFun} footer={<Fragment>
				<Button onClick={cancelFun}>Cancel</Button>
				<Button type="primary" disabled={btnDis} className="btn-w25 btn-primary-light" onClick={()=>form.submit()}>{props.detail?'Edit Announcement':'Add Announcement'}</Button>
			</Fragment>} >
		<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
		<Form.Item  label={<span><span className='pr-2' style={{ color: 'red' }}>*</span>Banner Images</span>} >
							 <MultiImageInput
									images={galleryImageUriList}
									setImages={onUploadGallery}
									allowCrop = {false}
									theme="light"
									max={1}
									cropConfig={{  minWidth: 10 , maxWidth: 2000  }}
									
    						/>
						</Form.Item>






			<Form.Item name="title" label="Title"  rules={[{ required: true, message: 'This field is required!' },{ max: 200, message: 'Title must not be greater than 200 characters.' },]} >
				<Input placeholder="Enter Title" />
			</Form.Item>
		</Form>

		<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
			<Form.Item name="message" label="Description"  rules={[{ required: true, message: 'This field is required!' },{ max: 1000, message: 'Description must not be greater than 1000 characters.' },]} >
			<TextArea placeholder="Description"  rows={5}  />
			</Form.Item>
		</Form>
		<Form {...formItemLayout} form={form} name="loc_info" layout="vertical" onFinish={onFinish} className="innerFields">
			<Form.Item name="type" label="Select Type"  rules={[{ required: true, message: 'This field is required!' },]} >
			<Select placeholder="Select Type" >
										{typeList && typeList.map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)}
									</Select>
			</Form.Item>
		</Form>
		
	</Modal>
)};

export default connect(({ announcements, global, loading }) => ({
  announcements:announcements,
  global: global,
  loading: loading 
}))(AddEdit);